<template lang="html">
  <v-container pa-0 fluid>
    <v-row dense>
      <v-col cols="12" v-if="stockId">
        <v-card color="white">
          <v-card-text class="pa-0">
            <v-container py-2 fluid>
              <v-row dense>
                <v-col cols="11" align-self="center" class="text-left">
                  <v-label-api :api-url="['models','Stock','Stocks','find']" :api-key="stockId" api-field="stockName" class="display-1"></v-label-api>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card> 
      </v-col>
      <v-col cols="12" v-if="stockId">
        <v-tabs-group>
          <template v-slot:tabs>
            <v-tab key="stocksproducts">Stocks Products</v-tab>
            <v-tab key="stockscards">Stocks Cards</v-tab>
          </template>
          <template v-slot:items>
            <v-tab-item key="stocksproducts">
              <v-stocks-products :stockId="stockId"></v-stocks-products>
            </v-tab-item>
            <v-tab-item key="stockscards">
              <v-stocks-cards :stockId="stockId"></v-stocks-cards>
            </v-tab-item>
          </template>
        </v-tabs-group>
      </v-col>
      <v-col cols="12" v-if="!stockId">
        <v-card color="white">
          <v-card-text class="pa-0">
            <v-container py-2>
              <v-row dense>
                <v-col cols="11" align-self="center" class="text-left">
                  <label class="headline">Stock ID is not configed for this client. Please contact administrator.</label>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card> 
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: ()=>({
    stockId: undefined
  }),
  mounted() {
    let location = this.$store.getters.getConfig('location')
    if (location && location.stockId) this.stockId = location.stockId
  }
}
</script>

<style lang="css" scoped>
</style>
